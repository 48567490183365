import React from 'react';
import { API } from '@stoplight/elements';

import '@stoplight/elements/styles.min.css';

const domain = location.protocol + '//' + location.host;

export const PrivatePartnerAPI = () => (
  <API
    apiDescriptionUrl={`${domain}/docs/private/partners-api.yml`}
    basePath="private/partners-api"
  />
);

export const PrivatePublisherAPI = () => (
  <API
    apiDescriptionUrl={`${domain}/docs/private/publishers-api.yml`}
    basePath="private/publishers-api"
  />
);

export const PrivateAdminAPI = () => (
  <API
    apiDescriptionUrl={`${domain}/docs/private/admin-api.yml`}
    basePath="private/admin-api"
  />
);

export const PublicPartnerAPI3 = () => (
  <API
    apiDescriptionUrl={`${domain}/docs/public/v3/partners-api.yml`}
    basePath="public/v3/partners-api"
  />
);

export const PublicPublisherAPI3 = () => (
  <API
    apiDescriptionUrl={`${domain}/docs/public/v3/publishers-api.yml`}
    basePath="public/v3/publishers-api"
  />
);

export const PrivateInternalAPI = () => (
  <API
    apiDescriptionUrl={`${domain}/docs/private/internal-api.yml`}
    basePath="private/internal-api"
  />
);

export const AdsterraComAPI = () => (
  <API
    apiDescriptionUrl={`${domain}/docs/private/adsterra-com-api.yml`}
    basePath="private/adsterra-com-api"
  />
);
